import React from "react"
import { Box, Typography, Container, styled, Stack, Grid } from "@mui/material"
import {
  ManageAccountsOutlined,
  AcUnitOutlined,
  ComputerOutlined,
  BugReportOutlined,
  DonutSmallOutlined,
  WysiwygOutlined,
  NetworkCheckOutlined,
  SecurityOutlined,
  VerifiedUserOutlined,
} from "@mui/icons-material"

import Title from "../../components/Title"
import Footer from "../../components/Footer"
//@ts-ignore
import ImgRecruitmentService from "../../resources/recruitment-service.jpg"

const ListBox = styled(Box)({
  borderRadius: "15px",
  borderWidth: "1px",
  borderColor: "#000000",
  boxShadow: "0px 0px 3px #165EE824",
  display: "flex",
  flexDirection: "column",
  "&:hover": {
    boxShadow: "0px 0px 12px #165EE824",
    cursor: "pointer",
  },
})

export default () => {
  return (
    <div className="App">
      <img
        src={ImgRecruitmentService}
        width="100%"
        height="450"
        style={{ objectFit: "cover" }}
      />
      <Title
        title="TECHNICAL STAFF"
        subTitle="Looking to Hire Technical Staff?"
      />

      <Container maxWidth="xl" sx={{ marginTop: 2 }}>
        <Box mb={3}>
          <Typography variant="body2">
            We understand your role, do the market research , advertise ,
            headhunt that one expert who fits perfectly in your open position.
            Our database of candidates includes top talents for Node.js,
            Javascript, python, Php , Data scientists and more
          </Typography>
          <Typography variant="body2">
            We help you find the exact requirement with a combination of skill
            sets which aligns with your Job role. We name it and we have it
            right from Web developers, Frontend Developers, Infrastructure
            roles, project management, Business Analysts and ERP roles. We help
            you find CTP and technology heads too.
          </Typography>

          <Box py={3}>
            <Typography
              variant="h5"
              align="center"
              sx={{
                fontSize: "21px",
                color: "#091188",
                fontWeight: "600",
                marginTop: 3,
              }}
            >
              Our Technology Recruitment expertise ranges from
            </Typography>
            <Typography align="center" variant="body2">
              We focus on recruiting for the following sectors.
            </Typography>
          </Box>
          <Stack flexDirection="row" gap={2} mb={2} mt={2} flexWrap="wrap">
            <ListBox
              height={200}
              width={200}
              justifyContent="center"
              alignItems="center"
            >
              <ManageAccountsOutlined fontSize="large" />
              <Typography variant="body2" align="center">
                CIO/ CTO
              </Typography>
            </ListBox>
            <ListBox
              height={200}
              width={200}
              justifyContent="center"
              alignItems="center"
            >
              <AcUnitOutlined fontSize="large" />
              <Typography variant="body2" align="center">
                Infrastructure
              </Typography>
            </ListBox>
            <ListBox
              height={200}
              width={200}
              justifyContent="center"
              alignItems="center"
            >
              <ComputerOutlined fontSize="large" />
              <Typography variant="body2" align="center">
                IT Support
              </Typography>
            </ListBox>
            <ListBox
              height={200}
              width={200}
              justifyContent="center"
              alignItems="center"
            >
              <BugReportOutlined fontSize="large" />
              <Typography variant="body2" align="center">
                Testing Engineers
              </Typography>
            </ListBox>
            <ListBox
              height={200}
              width={200}
              justifyContent="center"
              alignItems="center"
            >
              <DonutSmallOutlined fontSize="large" />
              <Typography variant="body2" align="center">
                Data Analysis
              </Typography>
            </ListBox>
            <ListBox
              height={200}
              width={200}
              justifyContent="center"
              alignItems="center"
            >
              <WysiwygOutlined fontSize="large" />
              <Typography variant="body2" align="center">
                ERP
              </Typography>
            </ListBox>
            <ListBox
              height={200}
              width={200}
              justifyContent="center"
              alignItems="center"
            >
              <NetworkCheckOutlined fontSize="large" />
              <Typography variant="body2" align="center">
                Network Engineer
              </Typography>
            </ListBox>
            <ListBox
              height={200}
              width={200}
              justifyContent="center"
              alignItems="center"
            >
              <SecurityOutlined fontSize="large" />
              <Typography variant="body2" align="center">
                Security Specialist
              </Typography>
            </ListBox>
            <ListBox
              height={200}
              width={200}
              justifyContent="center"
              alignItems="center"
            >
              <VerifiedUserOutlined fontSize="large" />
              <Typography variant="body2" align="center">
                IT Compliance and Audit
              </Typography>
            </ListBox>
          </Stack>
          <Box py={3}>
            <Typography
              variant="h5"
              align="center"
              sx={{
                fontSize: "21px",
                color: "#091188",
                fontWeight: "600",
                marginTop: 3,
              }}
            >
              Why Hiring Technical Experts?
            </Typography>
            <Typography align="center" variant="body2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
          </Grid>
          <Box py={3}>
            <Typography
              variant="h5"
              align="center"
              sx={{
                fontSize: "21px",
                color: "#091188",
                fontWeight: "600",
                marginTop: 3,
              }}
            >
              Why Choose Savanna HR for Technical Recruitment
            </Typography>
            <Typography align="center" variant="body2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ListBox p={2}>
                <Typography
                  variant="h4"
                  justifyContent="flex-start"
                  sx={{
                    fontSize: "0.7em",
                    fontWeight: "800",
                    marginBottom: 1,
                  }}
                >
                  Business Advancement
                </Typography>
                <Typography variant="body2">
                  Grow your business and profit with a team of dedicated
                  full-time employees with committed working hours and advanced
                  skills
                </Typography>
              </ListBox>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </div>
  )
}
